import React, { useState, useEffect, useReducer } from 'react';
import { MDBContainer, MDBRow, MDBBtn, MDBIcon } from "mdbreact"
import MessagesList from './components/messages-list';
import ModalConfirmSent from './components/confirm-send';
import ModalConfirmDiscard from './components/confirm-discard';
import ModalShowInfo from './components/show-info';
const { getPage, sentRequest, discard } = require('./components/api-service');

export default function Dashboard() {
  const [updated, forceUpdate] = useReducer(x => x + 1, 0);
  const [datalist, setDatalist] = useState([]);
  const [data, setData] = useState('')

  const [modalOpen, setModal] = useState('')
  
  const openModal = modal => {
    if (modal) setModal(modal)
  };

  const closeModal = () => {
    setModal('')
  };
  
  const genRequest = async () => {
    const params = {
      uuid: data.hash,
      recipients: data.extracted.map(item => {return { email: item.email }}),
      discard: false
    };
    closeModal();
    try {
      let response = await sentRequest(params);
      console.log('response', response);
      setData((d) => {return {...d, output: response.data.map(item => { 
          return (<p>{item.email}  {item.sended ? <MDBIcon far icon="check-circle" className="red-text" /> 
            : <MDBIcon far icon="times-circle" className="green-text" />}</p>);
      })}});
      response = await discard(params);
    } catch (err) {
      setData((d) => {return {...d, output: err}});
    };
    forceUpdate();
    setModal('modal-info');
  };

  const discardMessage = async () => {
    const params = {
      uuid: data.hash,
      discard: true
    };
    closeModal();
    await discard(params);
    let newArr = [...datalist];
    newArr[data.key].status = 'discarted';
    setDatalist(newArr);
    forceUpdate();
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await getPage();
        setDatalist(response);
      } catch(err) {
        console.log('Error', err);
      }
    })();
  }, []);

  return (
    <MDBContainer>
        <ModalConfirmSent
          item={data}
          handlerFn={genRequest}
          closeFn={closeModal}
          open={modalOpen === 'modal-confirm'} />
        <ModalConfirmDiscard
          item={data}
          handlerFn={discardMessage}
          closeFn={closeModal}
          open={modalOpen === 'modal-discard'} />
        <ModalShowInfo
          bodyText={data.output}
          closeFn={closeModal}
          open={modalOpen === 'modal-info'} />

        <MDBRow className="overflow-auto" style={{marginTop: "20px", marginBottom: "40px", maxHeight: "50vw" }}>
         { datalist.length > 0 
          ? 
            <MessagesList key={updated} elements={datalist} onSelect={setData} /> 
          : 
            <><h2>There is no data to display</h2></> }
        </MDBRow>

        <MDBRow>
          <MDBBtn onClick={() => openModal('modal-confirm')}>Send</MDBBtn>
          <MDBBtn onClick={() => openModal('modal-discard')}>Ignore request</MDBBtn>
        </MDBRow>
    </MDBContainer>
  );

};
