import React from "react";
import { MDBFooter } from "mdbreact";
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <MDBFooter color="primary-color" className="font-small mt-4 footer">
            <div className="d-flex justify-content-center py-3">
                <Link className="nav-link" to="/contactUs">Contact us</Link >
                <Link className="nav-link" to="/aboutUs">About us</Link >
            </div>
        </MDBFooter>
    );
};


export default Footer;

