import React from "react";
import {MDBRow, MDBCol } from "mdbreact";
import logoCharite from "../assets/img/Logo_Charite.png"
import "../assets/css/Logo.css"


const Logo = () => {
  return (
	  <MDBRow className="py-2 px-5 primary-color text-light">
      <MDBCol md="3">
        <img src={logoCharite} className="logo" alt="Charité" />
      </MDBCol>
    </MDBRow>
  );
};


export default Logo;