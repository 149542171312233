import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBBtn } from "mdbreact";
import { logOut } from "../helpers/auth";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(open => !open);
  };

  return (
    <MDBNavbar color="primary-color px-5" dark expand="md">
      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
        <MDBNavbarNav left>
          <MDBNavItem>
            <Link className="nav-link" to="/">Dashboard</Link >
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBBtn onClick={logOut} color="dark" size="sm">Log Out</MDBBtn>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};


export default Navbar;