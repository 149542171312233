import { errorMessage } from "../constants";
import { logOut } from "./auth";


export const handleErrors = error => {
  if (error.response?.status === 401) {
    logOut()
  } else if (error.response) {
    return error.response.data.message
  }
  return errorMessage;
};
