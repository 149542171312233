import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import ContactUsPage from './pages/contactUsPage/ContactUsPage';
import AboutUsPage from './pages/aboutUsPage/AboutUsPage';
import { token } from "./constants";
import Navbar from './components/Navbar';
import Logo from './components/Logo';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const authorized = localStorage.getItem(token);

    return (
        <Route {...rest}
            render={props => authorized
                ?
                <>
                    <Logo />
                    <Navbar />
                    <Component {...props} />
                </>
                :
                <Redirect to="/login" />
            } />
    );
};


const Routes = () => {
    return (
        <Switch>
            <Route path="/login" component={Login} />
            <Route path="/contactUs" component={ContactUsPage} />
            <Route path="/aboutUs" component={AboutUsPage} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/" component={Dashboard} />
            <Route render={()=><h2>Page not found.</h2>} />
        </Switch>
    );
};


export default Routes;