import { axiosInstance } from "../../../helpers/axiosSetup";

export {
    getPage,
    sentRequest,
    discard
}

const getPage = async () => {
    let res = await axiosInstance.post('/retrieve', {});
    return res.data.map((item, index) => {
        let status = 'new';
        if ("discarted" in item) {
            status = item.discarted ? 'ignored' 
                : item.extracted.filter(i => "application" in i).length + ' answ.'
        };
        return {
            key: index,
            hash: item.uuid,
            date: formatDate(item.processed_timestamp, 'DD/MM/YY hh:mm:ss'),
            title: item.p1_body.article.title,
            doi: item.p1_body.article.doi,
            journal: item.p1_body.journal.name,
            extracted: item.extracted,
            status,
            p1_body: item.p1_body,
            output: ''
        }
    });
};

const sentRequest = async (parameters) => {
    try {
        return await axiosInstance.post('/dispatch', parameters);
    } catch(err) {
        return err;
    }
};

const discard = async (parameters) => {
    try {
        return await axiosInstance.post('/discard', parameters);
    } catch(err) {
        return err;
    }
};

function formatDate(date, format) {
    const d = new Date(date);
    const map = {
        hh: d.getHours().toString().padStart(2, '0'),
        mm: d.getMinutes().toString().padStart(2, '0'),
        ss: d.getSeconds().toString().padStart(2, '0'),
        MM: (d.getMonth() + 1).toString().padStart(2, '0'),
        DD: d.getDate().toString().padStart(2, '0'),
        YY: d.getFullYear().toString().slice(-2),
        YYYY: d.getFullYear()
    }
    return format.replace(/hh|mm|ss|MM|DD|YY|YYYY/g, matched => map[matched])
};
