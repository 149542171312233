import React, { useState, useEffect } from 'react';
import { MDBDataTableV5 } from 'mdbreact';

export default function MessagesList({ elements, onSelect }) {
  const columns = [
    {
      label: 'Date',
      field: 'date',
      width: 200,
    },
    {
      label: 'Title',
      field: 'title',
      width: 400,
      attributes: {
        'aria-controls': 'DataTable', 
        'aria-label': 'Name',
      },
    },
    {
      label: 'DOI',
      field: 'doi',
      width: 250,
    },
    {
      label: 'Published in',
      field: 'journal',
      width: 250,
    },
    {
      label: 'Status',
      field: 'status',
      width: 250,
    },
  ];
  
  let [nestedData, setData] = useState({ columns, rows: elements });
 
  const [checkbox1, setCheckbox1] = useState('');
    
    const showLogs2 = (e) => {
      setCheckbox1(e);
      onSelect(e)
    };

    return (
      <>
        <MDBDataTableV5
          hover
          entriesOptions={[5, 20, 25]}
          entries={5}
          pagesAmount={4}
          data={nestedData}
          checkbox
          headCheckboxID='id2'
          bodyCheckboxID='checkboxes2'
          getValueCheckBox={(e) => {
            showLogs2(e);
          }}
        />
      </>      
    )
  
}
