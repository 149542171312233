import React from "react";
import { withFormik } from 'formik';
import "./Login.css";
import { MDBContainer } from 'mdbreact';
import LoginForm from "../../components/LoginForm";
import { logIn } from "../../helpers/auth";
import { axiosInstance } from "../../helpers/axiosSetup";
import { handleErrors } from "../../helpers/handleErrors";
import Logo from "../../components/Logo";

const Login = ({ handleSubmit }) => {
    return (
        <>
            <Logo />
            <MDBContainer className="form_wrapper d-flex justify-content-center">
                <LoginForm handleSubmit={handleSubmit} />
            </MDBContainer>
        </>
    );
};

const mapPropsToValues = () => ({
    email: "",
    password: "",
});

const handleSubmit = async (value, { props }) => {
    return await axiosInstance
        .post('authorize', value)
        .then(response => logIn(response, props))
        .catch(handleErrors);
};

export default withFormik({ handleSubmit, mapPropsToValues })(Login);