import React from "react";
import TextInput from "./TextInput";
import { Field } from 'formik';
import { MDBBtn } from 'mdbreact';

const LoginForm = ({ handleSubmit }) => {

    return (
        <form className="col-10 col-md-4" onSubmit={handleSubmit}>
            <p className="h4 text-center mb-3">Log in</p>
            <Field component={TextInput} name="email" label="Username" type="text" />
            <Field component={TextInput} name="password" label="Password" type="password" />

            <div className="text-center mt-4">
                <MDBBtn color="primary" type="submit">Login</MDBBtn>
            </div>
        </form>
    );
};

export default LoginForm;

