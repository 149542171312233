import { token } from "../constants";


export const logIn = (response, props) => {
    localStorage.setItem(token, response.data.token);
    props.history.push("/dashboard");
};

export const logOut = () => {
    localStorage.removeItem(token);
    window.location.reload();
};