import React from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact"


const ModalShowInfo = ({ bodyText = '', closeFn = () => null, open = false }) => {
  return (
<MDBModal isOpen={open} toggle={closeFn}>
    <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold">
        Result
      </MDBModalHeader>
      <MDBModalBody>
        { bodyText }
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <MDBBtn color="primary" onClick={closeFn}>OK</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default ModalShowInfo;