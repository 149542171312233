import React from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact"

const ModalConfirmDiscard = ({item, handlerFn = () => null, closeFn = () => null, open = false }) => {
  return (
    <MDBModal isOpen={open} toggle={closeFn}>
      <MDBModalHeader
        className="text-center"
        titleClass="w-100 font-weight-bold">
        Discard
      </MDBModalHeader>
      <MDBModalBody>
        { item ? <p>{item.title}</p> : null }
      </MDBModalBody>
      <MDBModalFooter className="justify-content-center">
        <MDBBtn color="primary" onClick={handlerFn}>Discard</MDBBtn>
        <MDBBtn color="secondary" onClick={closeFn}>Cancel</MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  )
}

export default ModalConfirmDiscard;