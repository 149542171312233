import React from "react";


const AboutUsPage = () => {
    return (
        <div className="container">
            <h2 className="ml-3">About us page</h2>
        </div>
    );
};


export default AboutUsPage;